import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.LOGIN_STATE:
      return { ...state, loginState: action.payload };
    case types.SET_LOGIN_TYPE:
      return { ...state, loginType: action.payload };
    case types.SET_ADMIN_TYPE:
      return { ...state, adminType: action.payload };
    case types.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case types.SET_STORE_LIGHT_THEME:
      return { ...state, storeLightTheme: action.payload };
    case types.SET_TIME_ZONE:
      return { ...state, timeZone: action.payload };
    case types.SET_CURRENCY:
      return { ...state, currency: action.payload };
    case types.SET_CURRENCY_TOGGLE_RIGHT_POS:
      return { ...state, currencyToggleRightPos: action.payload };
    case types.SET_CEILING:
      return { ...state, ceiling: action.payload };
    case types.SET_DEFAULT_COUNTRY_CHECKOUT_ID:
      return { ...state, defaultCountryCheckoutId: action.payload };
    case types.SET_LOADING_ANONYMOUS:
      return { ...state, loadingAnonymous: action.payload };
    case types.SET_PROFILE:
      window.clarity&&window.clarity("set", "userId", `${action.payload.family}:${action.payload.cellphone}:${action.payload._id}`);
      window.clarity&&window.clarity("set", "userIdShort", action.payload._id);
      window.clarity&&window.clarity("set", "userCellphone", action.payload.cellphone);
      return { ...state, profile: action.payload };
    case types.SET_TOKEN:
      return { ...state, token: action.payload };
    case types.SET_SOCKET:
      return { ...state, socket: action.payload };
    case types.SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case types.NEW_NOTIFICATION_RECIEVED:
        let newNotifications=[...state.notifications,action.payload];
        return { ...state , notifications:newNotifications};
    case types.SET_EMAIL:
      return { ...state, email: action.payload };
    case types.SET_REDIRECT:
      return { ...state, redirect: action.payload };
    default:
      return state;
  }
};

export const key = 'login';
export default reducer;
